<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6">
          <login-ui
              v-on:success="handleLogin"
          />
        </v-col>

      </v-row>
    </v-container>
    <message-dialog
        v-model="errorDialog"
        v-on:OK="errorDialog = false"
        title="Login failed"
    >
      <p>
        Oops! Login failed! Please try again!
      </p>
      <p>
        Or visit our <a href="/signup">sign up page.</a>
      </p>
    </message-dialog>
  </div>
</template>

<script>
import MessageDialog from "@/components/MessageDialog";
import LoginUi from "@/components/LoginUi";

export default {
  name: 'Login',
  metaInfo: {
      title: 'Login',
  },
  data: () => ({
    username: "",
    password: "",
    errorDialog: false,
  }),
  components: {MessageDialog, LoginUi},
  methods: {
    handleLogin: function() {
      this.$router.push(this.$route.query.redirect || '/')
    },
  },
  mounted() {
    // TODO: How to get links to login to pass the state
    // TODO: if user already logged in, redirect...
  },
}
</script>
